function successToolForm() {
	$.ajax({
		url: rctool_ajax_obj.ajaxurl,
		data: {
			action: "ajax_rctool",
			id: globalVars.postID,
		},
		success: function (data) {
			$(".main-content-container > .container").html(data);
		},
		error: function (errorThrown) {
			// console.log(errorThrown);
			// $(this).attr("disabled", false);
		},
	});
}

function successBanner(formWrapper) {
	$.ajax({
		url: notification_banner_ajax_obj.ajaxurl,
		data: {
			action: "ajax_notification_banner",
			content: formWrapper.attr("data-success-message"),
			icon: "check-circle",
			style: "success",
		},
		success: function (data) {
			formWrapper.replaceWith(data);

			if (formWrapper.attr("data-success-download")) {
				const downloadLink = formWrapper.attr("data-success-download");

				console.log("Download started...");

				// start downloading this file
				setTimeout(function () {
					window.open(downloadLink, "_blank");
				}, 1500);
			}
		},
	});
}

function successResult(formWrapper) {
	const type = formWrapper.attr("data-form-type");

	const successFunctions = {
		tool: successToolForm,
		newsletter: successBanner,
	};

	const successFunction = successFunctions[type] || console.log("success");

	successFunction(formWrapper);
}

function submitWebToLead(e) {
	e.preventDefault();

	const formWrapper = $(this);

	const sheetName = formWrapper.attr("data-sheet-name") || "Backup";

	console.log(sheetName);

	const formDataSheets = formWrapper.serialize() + "&sheetName=" + sheetName;

	const requestOptions = {
		method: "POST",
		mode: "no-cors",
	};

	const formData = new FormData(this);

	const endpoint = formData.get("endpoint");

	const params = new URLSearchParams();
	for (const pair of formData) {
		params.append(pair[0], pair[1]);
	}

	formWrapper
		.find('input[type="submit"], button[type="submit"]')
		.addClass("loading");

	fetch(`${endpoint}&${params}`, requestOptions)
		.then((response) => response.text())
		.then(() => successResult(formWrapper))
		.catch((error) => console.log("error", error));

	$.ajax({
		url: "https://script.google.com/macros/s/AKfycbwgmFKKzWIkn3aSA2se04kZXqWPAkXTOBGkpGn6sAr55lEcb9CW7xSjETfpVh0MYEwP/exec",
		method: "GET",
		dataType: "json",
		data: formDataSheets,
	})
		.done(function (data) {
			// console.log("Gsheet Success:", data);
		})
		.fail(function (xhr, status, error) {
			// console.log("Error:", error);
		});
}

$("body").on("submit", ".ch-form-wrapper", submitWebToLead);

// ! Extremely temporary solution that should be replaced by the end of August
$("body").on("submit", ".footer-newsletter .first-step form", function (e) {
	e.preventDefault();

	const formWrapper = $(this);
	const emailValue = formWrapper.find('input[name="email"]').val(); // find the input with name "email" and get its value

	formWrapper.find('button[type="submit"]').addClass("loading");

	setTimeout(function () {
		$(".footer-newsletter .second-step form").prepend(
			'<input type="hidden" name="email" value="' + emailValue + '">'
		);
		$(".footer-newsletter .first-step").remove();
		$(".footer-newsletter .second-step").show();
	}, 1000);
});

$("body").on("submit", ".footer-newsletter .second-step form", function (e) {
	e.preventDefault();

	const formWrapper = $(this);
	const formDataSheets = formWrapper.serialize() + "&sheetName=Backup";

	formWrapper.find('button[type="submit"]').addClass("loading");

	$.ajax({
		url: "https://script.google.com/macros/s/AKfycbwgmFKKzWIkn3aSA2se04kZXqWPAkXTOBGkpGn6sAr55lEcb9CW7xSjETfpVh0MYEwP/exec",
		method: "GET",
		dataType: "json",
		data: formDataSheets,
	})
		.done(function (data) {
			$(".footer-newsletter .second-step").remove();
			$(".footer-newsletter .third-step").show();
		})
		.fail(function (xhr, status, error) {
			console.log("Error:", error);
		});
});
